<template>
<div>
    <!-- <template v-if="!isMobile>" -->
    <div class="match-list__container" ref='scrollComponent'>
        <div class="match-list__group-selector-wrapper" v-if="false">
            <div class="relative" v-if="subLeagueList.length>1">
                <div v-if="!isMobile" class="nav-pill" :class="{'selected' : currentSubLeague.colored}" @click="handleSubLeagueBtn($event)" >
                    <span class="mr-1rem">{{$t(currentSubLeague.subLeagueName)}}</span>
                    <i class="svg-arrow__vertical "  :class="{'selected': currentSubLeague.colored}">
                        <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                        </svg>
                    </i>
                </div>

                <div id="subLeagueId" v-if="isMobile" class="nav-pill" :class="{'selected' : currentSubLeague.colored}" >
                    <span id="subLeagueName" class="mr-1rem">{{$t(currentSubLeague.subLeagueName)}}</span>
                    <i class="svg-arrow__vertical "  :class="{selected: currentSubLeague.isShowDropdown}">
                        <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                        </svg>
                    </i>
                </div>
                    
                <div v-if="!isMobile" class="dropdown-wrapper dropdown-right" :class="{'selected' : currentSubLeague.isShowDropdown}" >
                    <perfect-scrollbar>
                        <div class="dropdown-container__wrapper">
                            <div class="dropdown-container" v-for="(o,index) in subLeagueList" :key="index" :class="{'selected' : o.selected}" @click="handleSubLeagueDropdown(o ,false)">
                                <div class="dropdown-container__name">
                                    <span>{{$t(o.subLeagueName)}}</span>
                                </div>
                                <!--  :class="{'selected' : o.selected}" -->
                                <div class="dropdown-container__tick"></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
         <!-- v-show="!isLoading && !isShowNoData" -->
        <div class="match-wrapper" v-for="(o, oIndex) in matchList" :key="oIndex">
            <div class="display-flex-sb home-match__title-wrapper" v-if="oIndex === 0">
                <div class="display-flex-align-center home-match__league-info">
                    <div class="icon-league mr-05rem home-match__main-title-flag">
                        <img v-if="isWorldCup" :src="`${s3ImgUrl}/football/up/league/world_cup_2022_logo.png`" class="img-contain w-100 h-100" >
                        <img v-if="isUefa" :src="`${s3ImgUrl}/football/up/league/uefa_euro_2024.png`" class="img-contain w-100 h-100" >
                    </div>
                    <!-- <Icon  :logoUrl=o.logo class="mr-05rem home-match__main-title-flag"></Icon>    -->
                    <div class="home-match__title-container">
                        <!-- <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" >  -->
                        <!-- {{o.leagueName}} -->
                            <span v-if="!!o.leagueName && isWorldCup" class="home-match__title home-match__title-mobile"><span v-if="!isMobile">International | </span>FIFA World Cup 2022 &trade;</span>
                            <span class="home-match__country" v-if="isMobile && isWorldCup">International</span>

                            <span v-if="!!o.leagueName && isUefa" class="home-match__title home-match__title-mobile"><span>UEFA Euro 2024</span></span>
                            <!-- <span class="home-match__country" v-if="isMobile && isUefa">UEFA Euro 2024</span> -->
                            <!-- <span  v-if="o.leagueName && o.subLeagueName"> : </span><span v-if="!!o.subLeagueName"> {{o.subLeagueName}}</span> -->
                        <!-- </router-link> -->
                        <!-- <router-link v-if="!!o.leagueName" :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title-country" >{{o.country}}</router-link>
                        <router-link v-else :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" >{{o.country}}</router-link> -->
                    </div>
                </div>
            </div>
            <!-- getGroupStage  v-if="!!o.subLeagueName" -->
            <div class="home-match__subtitle"><span v-if="o.subLeagueName !== null">{{o.subLeagueName}} | </span>{{ o.roundEn }}</div>
            
            <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="display-flex-align-center home-match__title-wrapper" >
                <div class="home-match__title">{{o.country}}</div>
                <span class="home-match__title" v-if="o.leagueName !==null">| </span>
                <div class="home-match__title" v-if="o.leagueName !==null"> {{o.leagueName}}</div>
                <div class="home-match__title" v-if="!!o.subLeagueName">: {{o.subLeagueName}}</div>
                <div class="ml-s">
                    <svg width="6" height="10" viewBox="0 0 6 10"  class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                    </svg>
                </div> 
            </router-link> -->
            
            <!-- <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`}" target="_blank"              
                class="match-container"  v-for="(level2, index) in o.matchList" :key="index">    -->
            <div class="match-container"  v-for="(level2, level2Index) in o.matchList" :key="level2Index">
                <!-- live-stream-activated -->
                <div class="match-container-group-wrapper1" :class="{'live-stream-activated' : (level2.showCountDown || level2.isShowVideo)}">
                    <div
                        class="home-match__score-card__wrapper"
                        :class="{
                            'live-selected' : level2.isLive, 
                            'no-odds-match-top-rounded': noOdds && (level2.showCountDown || level2.isShowVideo),
                            'no-odds-match': noOdds && (!level2.showCountDown && !level2.isShowVideo)
                            }"
                    > 
                        <div class="home-match__status">
                            <span class="font-s">{{level2.matchStatus}}</span>
                            <!-- <div class="font-s" v-if="!!o.subLeagueName"> {{ o.subLeagueName.split("-")[1] }}</div> -->
                        </div>    
                        <div class="home-match__1-wrapper">
                            <template v-if="level2.opponents !== null">
                                <div class="display-flex home-match__1-container"  v-for="(level3, index) in level2.opponents" :key="index">
                                    <div class="display-flex-center font-s">
                                        <div class="icon-league home-match__icon-team" ><img :src="`${s3ImgUrl}/${level3.logo}`"></div>
                                        <span class="mr-1rem ">{{level3.name}}</span>
                                        <img class="img-contain" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">
                                    </div>
                                    <div>
                                        <div class="home-match__icon-team match-detail__score-wrapper match-detail__score bold font-s" >
                                            <span class="score">{{ level3.score }}</span>
                                            <span class="penalty-score score" v-if="level3.penaltyScore != null">{{ level3.penaltyScore }}</span>    
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="level2.opponents === null">
                                <div class="display-flex home-match__1-container" v-for="tbd in 2" :key="tbd">
                                    <div class="display-flex-center font-s">
                                        <div class="icon-league home-match__icon-team" ><img src="../../static/images/team_logo_default.png"></div>
                                        <span class="mr-1rem ">TBD</span>
                                    </div>
                                    <div>
                                        <div class="home-match__icon-team match-detail__score bold font-s" >-</div>
                                    </div>
                                </div>
                            </template>
                        </div>  
                        <div class="display-flex-center">
                            <!-- <div class="home-match__icon-match"></div> -->
                            <div v-if="level2.containLiveStream" class="home-match__icon-wrapper">
                                <div class="home-match__icon-livevideo"  @click="handleHighlight(o, level2, $event)"></div>
                            </div> 
                            <!-- <div  v-else class="home-match__icon-wrapper">
                                <div class="home-match__icon-highlight" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div>
                            </div>  -->
                            
                        </div>
                    </div>
                    <div class="home-match__static-card__wrapper"                   
                        @mouseenter="handleShowOddsDetailHover(o, level2, $event)" 
                        @mouseleave="handleShowOddsDetailLeave(o, level2, $event)"
                        v-if="!isMobile && !noOdds">
                        <OddsMatchContentOverview :oddsMatchData = "level2.oddsMatchOverview" :isShowBriefData = "oddsMatch.isShowBriefData"></OddsMatchContentOverview>
                        <OddsMatchContentDetail v-if="level2.isShowOddsDetail" :oddsMatchData="oddsMatch"></OddsMatchContentDetail>
                    </div>

                    <div class="home-match__static-card__wrapper"
                        @click="handleShowOddsDetailMobile(level2,$event)"
                        v-else-if="isMobile && !noOdds">
                        <OddsMatchContentOverview :oddsMatchData = "level2.oddsMatchOverview" :isShowBriefData = "oddsMatch.isShowBriefData"></OddsMatchContentOverview>
                    </div>
                </div>
                <!-- :groupStage="currentSubLeague" -->
                <!-- @countDownEnd="countDownEnd(oIndex, level2Index)" -->
                <LiveStreamCountDown
                    v-if="level2.showCountDown"
                    :matchDate="level2.matchDate"
                    :matchTime="level2.matchTime"
                    :groupName="o.subLeagueName"
                    :groupStage="level2.roundEn"
                    :class="{'uefa': isUefa}"
                ></LiveStreamCountDown>
                <!--  !level2.showCountDown -->
                <div v-if="level2.isShowVideo" class="live-stream-on-listing-compact">
                    <!-- <div class="live-stream-icon"></div> -->
                    <!-- @mousemove="mouseMovingOnVideo(level2.matchId)" 
                        @mouseleave="chooseChannel(level2.matchId, false)" 
                        @touchstart="mouseMovingOnVideo(level2.matchId)" 
                        @touchmove="mouseMovingOnVideo(level2.matchId)" 
                        @touchend="chooseChannel(level2.matchId, false)" -->
                    <video 
                        v-if="isWorld"
                        :id="level2.matchId"
                        class="video video-js vjs-default-skin w-100 match-detail__video-live" 
                        poster="../../static/images/world_cup_live_countdown_bg.jpg" 
                        autoplay
                        muted
                        controls 
                        playsinline 
                        @mouseenter="toggleChannelList(level2.matchId)"
                        @touchstart="toggleChannelList(level2.matchId)"
                    ></video>

                    <video 
                        v-if="isUefa"
                        :id="level2.matchId" 
                        class="video video-js vjs-default-skin w-100" 
                        poster="../../static/images/uefa_live_countdown_bg.jpg"
                        autoplay
                        controls 
                        playsinline 
                        muted
                        @mouseenter="toggleChannelList(level2.matchId)"
                        @touchstart="toggleChannelList(level2.matchId)"
                    ></video>

                    <!-- @mouseover="chooseChannel(level2.matchId, true)" 
                        @mouseout="chooseChannel(level2.matchId, false)" 
                        @touchstart="mouseMovingOnVideo(level2.matchId)" 
                        @touchmove="chooseChannel(level2.matchId, true)" 
                        @touchend="chooseChannel(level2.matchId, false)" -->
                    <div 
                        :id="channelsWrapper + level2.matchId" 
                        class="live-stream-channels-container"
                    >
                        <div class="live-stream-channel-pill" :class="{'selected': level2.selectedVideoSource === i}" v-for="(channel, i) in level2.liveStreamList" :key="i" @click="initLiveVideo(level2.matchId, true, i)">{{ $t("CH.") }} {{ i + 1 }}</div>
                    </div>
                </div>
            </div>
            <!-- </router-link>    -->
        </div>
    </div> 
   
    <!-- <template v-if="isMobile">
        <div class="home-match__wrapper" v-for="(o, index) in matchList" :key="index" v-show="!isLoading && !isShowNoData">
            <div class="display-flex-align-center home-match__title-wrapper">
                <Icon  :logoUrl=o.logo class="mr-05rem home-match__title-flag"></Icon>   
                <div class="home-match__title-container">
                    <router-link :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" > 
                        <span v-if="!!o.leagueName">{{o.leagueName}}</span>  
                        <span  v-if="o.leagueName && o.subLeagueName"> : </span><span v-if="!!o.subLeagueName"> {{o.subLeagueName}}</span>
                    </router-link>
                    <router-link v-if="!!o.leagueName" :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title-country" >{{o.country}}</router-link>
                    <router-link v-else :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}`,query: { leagueId: o.leagueId, subLeagueId: o.subLeagueId }}" class="home-match__title home-match__title-mobile" >{{o.country}}</router-link>
                </div> -->
 
                <!-- <div class="ml-s">
                    <svg width="6" height="10" viewBox="0 0 6 10"  class="home-match__title-icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                    </svg>
                </div>  -->

            <!-- </div>           
            <div class="home-match__container" >                
                <router-link 
                    :to="{path:`/${currentLocale}/${currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`}" 
                    target='_blank'  
                    class="home-match__card home-match__card-odds" 
                    :class = "{'home-match__card__status-top' : isShowStatusAtTop, 'live-selected' : level2.isLive}" 
                    v-for="(level2, index) in o.matchList" :key="index"
                    @click="$tools.openNewWindow($event)" >                          
                    <div class="display-flex w-100">
                        <div class="home-match__card-odds-content">        
                            <div class="home-match__detail"  v-for="(level3, index) in level2.opponents" :key="index">
                                <div class="home-match__detail-opponent">
                                    <Icon   :logoUrl=level3.logo  class="home-match__icon"></Icon>
                                    <span>{{level3.name}}</span>
                                    <img class="ml-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">							
                                </div>     

                                <div class="display-flex">
                                    <div class="home-match__detail-score" >{{level3.score}}</div>
                                </div>                      
                            </div>
                        </div>
                        <div  v-show="isShowWinLose">
                            <div class="home-match__win-lose" :class="level2.winLose">{{level2.winLose}}</div>
                        </div>
                    
                        <div class="home-match__static-card__wrapper" @click="handleShowOddsDetailMobile(level2,$event)">
                            <OddsMatchContentOverview :oddsMatchData = "level2.oddsMatchOverview" :isShowBriefData = "oddsMatch.isShowBriefData"></OddsMatchContentOverview>
                        </div>
                    </div>       
                    <div class="home-match__card-odds-bottom">
                        <div class="home-match__card-odds-time" :class="{'match-detail__status-w-auto' : isStatusWidthAuto}">
                            <span :class="{'match-status__live': level2.matchStatus ==='Live'}" > {{level2.matchStatus}}</span>							
                        </div>
                        <div class="display-flex-center">
                            <div class="home-match__icon-match__mobile"></div>
                            <div v-if="level2.containLiveStream" class="home-match__icon-wrapper">
                                <div  class="home-match__icon-livevideo__mobile"  @click="handleHighlight(o, level2, $event)"></div>
                            </div>
                            <div v-else  class="home-match__icon-wrapper">
                                <div  class="home-match__icon-highlight__mobile" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> 
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>   
    </template> -->

    <div v-show="isLoading && !isShowNoData"  class="skeleton-match__wrapper">
        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>
        
        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

         <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>

        <div class="mb-2rem">
            <div class="skeleton-child skeleton-match__title"></div>
            <div class="skeleton-child skeleton-match__content"></div>
            <div class="skeleton-child skeleton-match__content"></div>
        </div>
    </div>
    <!-- <Loading v-show="isLoading && !isShowNoData"></Loading> -->
    <div class="no-data" v-show="!isLoading && isShowNoData">{{$t('NO_MATCHES')}}</div>
     <ModalTransparent ref="modalPopupRef" >
        <OddsMatchContentDetail :oddsMatchData="oddsMatch"></OddsMatchContentDetail>
    </ModalTransparent>
 </div>

</template>

<script>

import {mapGetters,mapActions} from 'vuex'
import MobileSelect from "@/lib/mobile-select";
import OddsMatchContentDetail from "@/components/match/OddsMatchContentDetail.vue";
import OddsMatchContentOverview from "@/components/match/OddsMatchContentOverview.vue"
import ModalTransparent from '@/components/modal/ModalTransparent.vue'
import LiveStreamCountDown from "@/components/LiveStreamCountDown.vue";

import moment from "moment";
import config from '@/js/config.js'
import Hls from "hls.js";
let mobileSelectSubLeague; // defined mobile select dropdown component for subLeague
let mobileSelect3;
let firstTimeLoad = true;
// let mouseMoveOnVideoTimeOut = null;
export default {
    components: {
        OddsMatchContentDetail,
        OddsMatchContentOverview,
        ModalTransparent,
        LiveStreamCountDown,
    },

    props: {
        matchObj: {
            type: Array
        },
        isShowFav : {
            type:Boolean
        },
        isShowWinLose : {
            type:Boolean
        },
        WinLoseResult: {
            type: String
        },
        params:{
            type:Object
        },
        selectedDate:{
            type:String
        },
        isStatusWidthAuto: {
             type:Boolean,
             default:false
        },
        isShowStatusAtTop: {
             type:Boolean,
             default:false
        },
    },

    watch:{
		params:{
			deep:true,
            handler(newVal,oldVal) {             
                if (typeof newVal.leagueKey !== 'undefined') {
                    // this.getFixtureByLeagueData(newVal);
                }
                else {
                    this.getMatchListData(newVal);
                }				
			}
		},
        selectedDate:{
            handler(newVal,oldVal) {
                if (this.subLeagueList.some(el => el.date === newVal)) {
                    this.currentSubLeague.colored = true;
                } else {
                    this.currentSubLeague.colored = false;
                }
                this.subLeagueList.forEach((el, i) => {
                    el.selected = false;
                    if (el.date === newVal) {
                        el.selected = true;
                        this.currentSubLeague.subLeagueName = el.subLeagueName;
                        // sync mobileSelectSubLeague wheel with weekly/calendar picker selected date
                        if (typeof mobileSelectSubLeague !== 'undefined')  {
                            mobileSelectSubLeague.locatePosition(0,i);
                        }
                    }
                })
            }
        },
    },

    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            isLoading: false,
            isShowNoData: false,
            path: [],
            
            matchList: [],
            tmpMatchList: [],

            //odds info
            oddsMatch: {
                isShow:  false,
                list: [],// store initial odds list
                isShowDetail: false,
                
                detailStyle: {},
                detailList: {},//store current hover display odds detail list
                isShowBriefData: false
            },

            isShowOddsDetailMobile: false,
            count: 2,
            subLeagueList: config.worldCupSubLeagueList,
            currentSubLeague: {
                subLeagueName: '',
                isShowDropdown: false,
                colored: false,
            },

            channelsWrapper: "ChannelsWrapper_",
            selectedChannelList: [],
            noOdds: true,
        }
    },

	computed: {
        ...mapGetters([              
            'isMobile',
            'currentLocale',
            'currentSportType',
            "isWorldCup",
            "isUefa",
        ]),
        matchList(){ 
            //filter for matchList if score ==="", then show "?"
            let matchList = this.matchList;
            let matchLivestreamInfo = [];
            let isMatchEndList = [];
            let liveStreamIdList = [];
            if (!firstTimeLoad) {
                this.selectedChannelList.forEach(selectedChannel => {
                    liveStreamIdList.push(selectedChannel.matchId);
                });
            }

            for (let level1 = 0; level1<matchList.length; level1++) {
                let subLeagueName = matchList[level1].subLeagueName;
                if (subLeagueName !== null && subLeagueName.indexOf(" - ") !== -1) {
                    matchList[level1].subLeagueName = subLeagueName.split(" - ")[1];
                }

                for (let level2 = 0; level2<matchList[level1].matchList.length; level2++){
                    let theMatch = matchList[level1].matchList[level2];
                    // stop interval API call if current time exceeds all matches' "end time"
                    let matchStart = theMatch.matchDate + " " + theMatch.matchTime;
                    // let matchStartMoment = moment(matchStart, "YYYY-MM-DD HH:mm");
                    // let normalMatchEnd = moment(matchStart, "YYYY-MM-DD HH:mm").add(90, "m").format("YYYY-MM-DD HH:mm"); // normal match duration is 90 minutess
                    // for match status = Pen. but without live stream to determine whether if match ends
                    let forceMatchEnd = moment(matchStart, "YYYY-MM-DD HH:mm").add(130, "m").format("YYYY-MM-DD HH:mm"); // 40 minutes extra after normal match duration
                    // wait 15 minutes after start time and verify if live stream available
                    let waitForLiveStream = moment(matchStart, "YYYY-MM-DD HH:mm").add(15, "m").format("YYYY-MM-DD HH:mm"); // 15 minutes after match start time

                    if (theMatch.containLiveStream) {                        
                        theMatch.showCountDown = false;
                        theMatch.isShowVideo = true;
                        if (theMatch.liveStreamList) {
                            if (firstTimeLoad) {
                                theMatch.selectedVideoSource = 0;
                            } else {
                                // after 1st load, live stream which available after that, highlight default selected channel
                                if (!liveStreamIdList.includes(theMatch.matchId)) {
                                    theMatch.selectedVideoSource = 0;
                                } else {
                                    this.switchToNewLiveStreamChannel(theMatch);
                                }
                            }
                            theMatch.hls = null;
                            matchLivestreamInfo.push({
                                matchId: theMatch.matchId,
                                selectedVideoSource: theMatch.selectedVideoSource,
                                selectedVideoURL: theMatch.liveStreamList[theMatch.selectedVideoSource].url,
                            });
                            this.initLiveVideo(theMatch.matchId);
                        }
                    } else if (moment().isAfter(waitForLiveStream) && !theMatch.containLiveStream) {
                        theMatch.showCountDown = false;
                        theMatch.isShowVideo = false;
                    } else if ((moment().isBefore(forceMatchEnd))) {//normalMatchEnd
                        theMatch.showCountDown = true;
                        theMatch.isShowVideo = false;
                    }
                    if (theMatch.opponents !== null) {
                        for (let level3 = 0; level3<theMatch.opponents.length; level3++){
                            if (theMatch.opponents[level3].score ===""){
                                theMatch.opponents[level3].score = "-"
                            }
                        }
                    }
                    // 1st condition is to check if match (with status 'Pend.') ends
                    // last condition is when ongoing match without livstream
                    // moment().isAfter(forceMatchEnd)
                    if (
                        // (moment().isAfter(normalMatchEnd) && theMatch.liveStreamList === null) || 
                        theMatch.matchStatus === "FT" || 
                        theMatch.matchStatus === "AET"
                        //  || (moment().isAfter(matchStartMoment) && moment().isBefore(normalMatchEnd) && theMatch.liveStreamList === null)
                    ) {
                        isMatchEndList.push(true);
                        theMatch.showCountDown = false;
                        // 22/11/2022 - YIM: comment out, livestream return mechanism enhanced
                        // livestream no longer rely on matchStatus === "FT", but disable it on !containLiveStream
                        // theMatch.isShowVideo = false;
                    } else {
                        isMatchEndList.push(false);
                    }
                }
            }

            if (isMatchEndList.every(ele => ele === true)) {
                this.$emit('stopAPICallInterval');
            }

            this.selectedChannelList = matchLivestreamInfo;

            if (this.matchList.length !== 0) {
                firstTimeLoad = false;
            }

            return matchList
        },
        // getGroupStage() {
        //     if (this.currentSubLeague.colored && this.currentSubLeague.subLeagueName !== config.worldCupSubLeagueList[0].subLeagueName) {
        //         return " | " + this.currentSubLeague.subLeagueName;
        //     } else {
        //         return;
        //     }
        // },
	},
    created() {      
        this.path=this.$route.path;         
    },

    beforeMount() {
       //window.addEventListener('scroll', this.handleScroll)
    },
    mounted() {
        if (!this.isWorldCup) {
            this.getSoccerSubLeagueListData();// get subleague data and bind to dropdown
        }
        document.addEventListener('click', this.documentClick);
    },
  
    methods:{
        ...mapActions([
            "getMatchListAsync2",
            "getMatchListUEFA",
            "getMatchListv2WCIframe",
            "getFixtureByLeague",
            "getMatchListByTeam"
        ]),

        replaceByDefault(e) {  
          e.target.src = `${this.s3ImgUrl}/team/default.png`
        },

        async getMatchListData(newVal, interval = false) {
            let params={
				matchDate:newVal.date,
                liveOnly: newVal.isLiveOnly,
                lang:this.currentLocale,
				timeZone:this.$tools.getCurrentTimeZone(),//"Asia/Bangkok",
			};
            if (!this.isWorldCup) {
                params.leagueIdList = newVal.leagueIdList.toString();
            } else if (this.isWorldCup) {
                params.leagueIdList = 75;
                
                // this.subLeagueList.forEach(stage => {
                //     if (stage.qatarDate !== "") {
                //         let qatarFullDate = stage.qatarDate.split("T")[0];
                //         let qatarDate = qatarFullDate.split("-")[2] * 1;
                //         let ISOString = new Date(stage.qatarDate).toISOString();
                //         let localDateObj = new Date(ISOString);
                //         let localFullDate = localDateObj.getFullYear() + "-" + (localDateObj.getMonth() + 1) + "-" + localDateObj.getDate();
                //         stage.date = (qatarDate === localDateObj.getDate()) ? qatarFullDate : localFullDate;
                //     } 
                // });
                // this.getSoccerSubLeagueListData();
            }

            if (!newVal.isCallInterval && !interval) {
                this.isLoading = true;    
                // this.matchList = [];
            } else {
                this.isLoading = false;
            }

           // this.isShowNoData = false;

            const theDate = moment(params.matchDate).startOf("day");
            const today = moment().startOf("day");
            const diffDay = theDate.diff(today, "days");
            let isPreviousDate = diffDay < 0  ? true : false;
            
            let result;
            if (this.isWorldCup) {
                result = await this.getMatchListv2WCIframe(params);
            } else if (this.isUefa) {
                result = await this.getMatchListUEFA(params);
            } else {
                result = await this.getMatchListAsync2(params);
            }
          
            if (result.result === null) {
                this.matchList = [];
                this.isShowNoData = true;
            } else {
                if (result.result.leagueMatchList.length === 0) {       
                    this.matchList = [];                       
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                    this.matchList = result.result.leagueMatchList;
                    this.oddsMatch.list = result.result.oddsListMatchMap;

                    // console.log("GET DATA this.matchList ",this.matchList);

                    //only live, today and and future day show 6 data at match odds overview 
                    if (newVal.isLiveOnly) {
                        this.oddsMatch.isShowBriefData = false
                    } else if (isPreviousDate){
                        this.oddsMatch.isShowBriefData = true
                    } else {
                        this.oddsMatch.isShowBriefData = false;
                    }

                    //mapping odds value to match list
                    for (let i = 0; i < this.matchList.length; i++) {
                        let level1 = this.matchList[i].matchList;
                        if (!newVal.isCallInterval) {
                            level1.isShowOddsDetail = false;
                        } 
                            
                        if (this.oddsMatch.list !== null) {
                            for (let x = 0; x < level1.length; x++) {
                                level1[x].oddsMatchOverview = this.oddsMatch.list[level1[x].matchId];               
                            }
                        }
                    }        
                    setTimeout(() => {
                        this.$emit('changeMatchContentEvent', this.matchList); //broacast event to those who subscribe it 
                    }, 100);
                }
            }
            
            if (this.isWorldCup && !interval) {
                this.$emit("worldCupContentLoaded");
            }

           this.isLoading = false;
    	},

        // mouseMovingOnVideo(matchId) {
        //     clearTimeout(mouseMoveOnVideoTimeOut);
        //     let theChannelWrapper = document.getElementById(this.channelsWrapper + matchId);

        //     this.matchList.forEach(league => {
        //         league.matchList.forEach(match => {
        //             if (match.matchId === matchId) {
        //                 theChannelWrapper.style.opacity = 1;
        //                 mouseMoveOnVideoTimeOut = setTimeout(() => {
        //                     theChannelWrapper.style.opacity = 0;
        //                 }, 2000);
        //             }
        //         });
        //     });
        // },

        // chooseChannel(matchId, mouseover) {
        //     clearTimeout(mouseMoveOnVideoTimeOut);
        //     let theChannelWrapper = document.getElementById(this.channelsWrapper + matchId);
        //     theChannelWrapper.style.opacity = mouseover ? 1 : 0;
        // },

        toggleChannelList(matchId) {
            let theChannelWrapper = document.getElementById(this.channelsWrapper + matchId);
            let video = document.getElementById(matchId);
            let toggleTimeout;
            let actions = ["mousemove","touchmove"];
            actions.forEach(action => {
                video.removeEventListener(action, () => {});
            });
        
            this.matchList.forEach(league => {
                league.matchList.forEach(match => {
                    if (match.matchId === matchId) {
                        theChannelWrapper.style.opacity = 1;
                        toggleTimeout = setTimeout(() => {
                            theChannelWrapper.style.opacity = 0;
                            clearTimeout(toggleTimeout);
                        }, 2000);

                        actions.forEach(action => {
                            video.addEventListener(action, () => {
                                clearTimeout(toggleTimeout);
                                theChannelWrapper.style.opacity = 1;
                                toggleTimeout = setTimeout(() => {
                                    theChannelWrapper.style.opacity = 0;
                                    clearTimeout(toggleTimeout);
                                }, 2000);    
                            });
                        });
                    }
                });
            });
        },

        switchToNewLiveStreamChannel(theMatch, fromSwitchChannel = false) {
            this.selectedChannelList.forEach(selectedChannel => {
                if (theMatch.matchId === selectedChannel.matchId) {
                    theMatch.selectedVideoSource = selectedChannel.selectedVideoSource;
                }
            });
            let theChannelWrapper = document.getElementById(this.channelsWrapper + theMatch.matchId);
            if (fromSwitchChannel) {
                theChannelWrapper.style.opacity = 0;
            }
        },

        initLiveVideo(matchId, isSwitchChannel = false, switchToChannel = 0) { // need to verify if containLiveStream, liveStreamList beforehand
            this.$nextTick(() => {
                this.matchList.forEach(league => {
                    league.matchList.forEach(match => {
                        if (match.matchId === matchId) {
                            let video = document.getElementById(match.matchId);
                            let videoUrl;
                            if (video.src === "") {
                                videoUrl = match.liveStreamList[0].url;
                            } else if (isSwitchChannel) {
                                videoUrl = match.liveStreamList[switchToChannel].url;
                                this.selectedChannelList.forEach(match => {
                                    if (match.matchId === matchId) {
                                        match.selectedVideoSource = switchToChannel;
                                    }
                                });
                                
                                this.switchToNewLiveStreamChannel(match, isSwitchChannel);
                            }
                            if (video.canPlayType("application/vnd.apple.mpegurl")) {
                                if (video.src === "" || isSwitchChannel) {
                                    video.src = videoUrl;
                                    video.play();
                                }
                                // if (isSwitchChannel) {
                                //     video.src = videoUrl;
                                //     video.play();
                                // }
                            } else if(Hls.isSupported()) {
                                if (video.src === "" || isSwitchChannel) {
                                    if (isSwitchChannel && match.hls) {
                                        match.hls.destroy();
                                        clearInterval(match.hls.bufferTimer);
                                        match.hls = null;
                                    }
                                    let hls = new Hls();
                                    hls.loadSource(videoUrl);
                                    hls.attachMedia(video);
                                    match.hls = hls;
                                    if (isSwitchChannel) {
                                        video.play();
                                    }
                                }
                            }
                            
                            // let playPromise;
                            // if (video.src === "") {
                            //     playPromise = video.play();
                            //     if (playPromise !== undefined) {
                            //         playPromise.then(() => {
                            //             video.pause();
                            //         }).catch(err => {});
                            //     }
                            // }
                        }
                    });

                });

                this.playLatestLivstream();
            });
        },

        // play latest frame of live stream after back from pause
        playLatestLivstream() {
            let videos = document.getElementsByTagName("video");
            videos.forEach(video => {
                video.removeEventListener("play", () => {});
            });
            videos.forEach(video => {
                video.addEventListener("play", () => {
                    let videoPlaying = document.getElementById(video.id);
                    if (videoPlaying.duration === Infinity) {
                        let url;
                        this.selectedChannelList.forEach(selectedChannel => {
                            if (selectedChannel.matchId == video.id) {
                                url = selectedChannel.selectedVideoURL;
                            }
                        });
                        videoPlaying.src = url;
                        videoPlaying.play();
                    } else if (!isNaN(videoPlaying.duration)) {
                        videoPlaying.currentTime = videoPlaying.duration - 1;
                    }
                });
            });
        },

        async getMatchListByTeamData(newVal){
            let params={
				teamId:newVal.teamId,
				timeZone:this.$tools.getCurrentTimeZone()
			};

            if (!newVal.isCallInterval) {
                this.isLoading = true;            
            } else {
                this.isLoading = false;
            }

            const result = await this.getMatchListByTeam(params);
            this.isLoading = false;
  
            if (result.result === null) {
                  this.isShowNoData = true;
            } else {
                if (result.result.leagueMatchList.length === 0) {                
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false
                }
            }

           this.matchList =  result.result.leagueMatchList;
           this.$emit('changeMatchContentEvent',this.matchList);
    	},
       
	    async getFixtureByLeagueData(newVal){
			let params = {
				leagueKey:newVal.leagueKey,
				matchDate:newVal.matchDate,
				timeZone:this.$tools.getCurrentTimeZone()
			}
		    this.isLoading = true;
			const result = await this.getFixtureByLeague(params);
            this.isLoading = false;
			this.matchList = result.result.matchList;
           
            if (this.matchList.length == 0) {                
                this.isShowNoData = true;
            } else {
                this.isShowNoData = false
            }

			this.$emit('changeMatchContentFixtureByLeagueEvent',this.matchList);
		},
        handleMatchDetailRoutePath(o, level2, e){
            // if (level2.containLiveStream) {
            //     e.preventDefault();
            //     e.stopPropagation();
                
            //     let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
            //     window.open(link);
            //     return false
            // } else {
            //     this.$tools.openNewWindow(e)
            // }
        },
        //redirect to highlightpage if the match contain highlight video
        handleHighlight(o, level2, e) {
            if (level2.containLiveStream) {
                e.preventDefault();
                e.stopPropagation();
                
                // let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
                // window.open(link);
                level2.isShowVideo = !level2.isShowVideo;
                level2.showCountDown = false;
                if (level2.isShowVideo)
                    this.initLiveVideo(level2.matchId);
                return false
            }
            else if (level2.containHighlight) {
                e.preventDefault();
                e.stopPropagation();

                this.$router.push({ path: `/${this.currentLocale}/${this.currentSportType}/highlights/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`, query: { date: level2.matchDate } })
                return false
            } else {
                e.preventDefault();
                e.stopPropagation();
                
                // let link = `/${this.currentLocale}/${this.currentSportType}/${o.countryKey}/${o.leagueKey}/${level2.matchName}/${level2.matchId}`;            
                // window.open(link);
                return false
            }      
        },
        
        selectFavMatch(o){
            o.fav =!o.fav
        },

        handleScroll(){       
            let element = this.$refs.scrollComponent;

            if (element.getBoundingClientRect().bottom < window.innerHeight) {
                this.loadMorePosts()
            }
        },
        
        loadMorePosts(){
            if (this.tmpMatchList.length > 0) {
                let gg = this.count + 2;
                 
                 for (let i = this.count; i < gg; i++) {
                        this.matchList.push(this.tmpMatchList[i])
                    }
                    this.count = this.count+2;
                if ( this.count < this.tmpMatchList.length +2) {
                   
                }
       
            }
  
        },

        handleShowOddsDetailHover(o,level2, event) {
            if (this.oddsMatch.list[level2.matchId] && !this.oddsMatch.isShowBriefData) {
                level2.isShowOddsDetail = true;
                this.oddsMatch.detailList = this.oddsMatch.list[level2.matchId];
            }
        },

        handleShowOddsDetailLeave(o,level2) {
            level2.isShowOddsDetail = false;
        },
        
        handleShowOddsDetailMobile(level2,e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.oddsMatch.list[level2.matchId] && !this.oddsMatch.isShowBriefData) {
                this.oddsMatch.detailList = this.oddsMatch.list[level2.matchId];
                this.$refs.modalPopupRef.handleOpen();
            }
        },

        //get soccer subleague list async 
        getSoccerSubLeagueListData() {
            let mobileSelectData = [];
            // let mobileObj = {
            //     id: '',
            //     value: ''
            // }
            // mobileObj.id = this.subLeagueList[0].date;
            // mobileObj.value =this.$i18n.t(this.subLeagueList[0].subLeagueName);
            // mobileSelectData.push(mobileObj);

            // let params = {
            //     leagueId : this.leagueId   
            // }     
                     
            // const result = await this.getSoccerSubLeagueList(params);

            if (this.currentSubLeague.subLeagueName === "") {
                this.currentSubLeague.subLeagueName = this.subLeagueList[0].subLeagueName;
            }
        
            if (this.subLeagueList.length != 0) {
                // this.subLeagueList.length = 1; //reset subLeagueList array and only keep first array;
                this.subLeagueList.forEach( x=> {
                    // (Mobile) insert subleague data to mobile select
                    let mobileObj = {
                        id: '',
                        value: ''
                    }
                    mobileObj.id = x.date;
                    mobileObj.value = x.subLeagueName;

                    mobileSelectData.push(mobileObj);
                    
                    x.selected = false;
     
                    // // (Dekstop) insert subleague data to desktop select
                    // this.subLeagueList.push(x);
                      
                    // // selected data -- not applicable in this case
                    // if (x.date === this.date) {    
                        // let isFirstTimeLoadData = true;            
                        // this.handleSubLeagueDropdown(x, isFirstTimeLoadData);
                    // }
                })
                
                //(Mobile) initial mobile subleague select
                if (this.isMobile) {
                    this.$nextTick(() => {
                        //store vue js this to that
                        let that = this;

                        // temporary solution for translate in js (idealy all translation word store in json file )
                        const i18nEnsureBtnText ={
                            en: 'Done',
                            vn: 'xong',
                            th: 'เสร็จสิ้น'
                        }

                        const i18nCancelBtnText ={
                            en: 'Cancel',
                            vn: 'hủy bỏ',
                            th: 'ยกเลิก'
                        }

                        const i18nTitle = {
                            en: 'Select stage',
                            vn: 'Chọn nhóm',
                            th: 'เลือกกลุ่ม'
                        }

                        let triggerId = '#subLeagueId';
                        let title = i18nTitle[this.currentLocale];
                        let ensureBtnText = i18nEnsureBtnText[this.currentLocale];
                        let cancelBtnText = i18nCancelBtnText[this.currentLocale];
                        let ensureBtnColor = '#61CE70';
                        let titleBgColor = "#F0F0F0";

                        if (typeof mobileSelectSubLeague === "undefined") {
                            mobileSelectSubLeague = new MobileSelect({
                                trigger: triggerId,
                                triggerDisplayData: false,
                                title: title,
                                ensureBtnText: ensureBtnText,
                                cancelBtnText: cancelBtnText,
                                ensureBtnColor: ensureBtnColor,
                                titleBgColor: titleBgColor,

                                wheels: [
                                    { 
                                        data: mobileSelectData,
                                    },
                                ],
                            
                                callback: function (indexArr, data) {
                                    that.currentSubLeague.subLeagueName = data[0].value;
                                    that.currentSubLeague.date = data[0].id;
                            
                                    if (that.currentSubLeague.date === '') {
                                        that.currentSubLeague.colored = false;
                                    } else {
                                        that.currentSubLeague.colored = true;
                                        that.$emit("changeCurrentDateEvent", that.currentSubLeague.date);
                                        that.getMatchListData(that.currentSubLeague);
                                    }

                                    // that.getFixtureByLeagueData();                        
                                },
                            });
                        }
                    
                        if (this.selectedDate) {
                            // sync mobileSelectSubLeague wheel with weekly/calendar picker selected date
                            that.subLeagueList.forEach((el, i) => {
                                if (el.date === this.selectedDate) {
                                    mobileSelectSubLeague.locatePosition(0,i);
                                }
                            });
                        } else if (this.date === null || typeof this.date === 'undefined') {
                            mobileSelectSubLeague.locatePosition(0,0)
                        } else {
                            for (let i=0; i<mobileSelectData.length; i++) {
                                if (mobileSelectData[i].id === this.date) {    

                                    that.currentSubLeague.subLeagueName =  mobileSelectData[i].value;   
                                    that.currentSubLeague.colored = true;     

                                    mobileSelectSubLeague.locatePosition(0,i)
                                }
                            }
                        }                   
                    });
                }

            } else {
                let isFirstTimeLoadData = true;            
                this.handleSubLeagueDropdown(this.subLeagueList[0], isFirstTimeLoadData);
            }

            //(Desktop) if query string date is empty default pass 'All Groups' data to getFixturebyleague  
            if (!this.isMobile) {
                 if (this.date === null || typeof this.date === 'undefined') {
                    let isFirstTime = true;
                    this.handleSubLeagueDropdown(this.subLeagueList[0], isFirstTime);                
                }
            } else {
                 this.currentSubLeague.subLeagueName = mobileSelectData[0].value; 
            }     
           
        },

        handleSubLeagueBtn(e){
            this.currentSubLeague.isShowDropdown = !this.currentSubLeague.isShowDropdown;
            e.stopPropagation();
        },

        //(Desktop) subLeague dropdown select event
        handleSubLeagueDropdown(o, isFirstime){
            if (this.isMobile) {
                return;
            }
            this.subLeagueList.forEach(x => {
                x.selected = false;
            })
            o.selected = true;

            this.currentSubLeague.subLeagueName = o.subLeagueName;
            this.currentSubLeague.isShowDropdown = false;
            
            if (o.date !== "") {
                this.currentSubLeague.date = o.date;
                this.$emit("changeCurrentDateEvent", this.currentSubLeague.date);

                //first time initial load dont call getFixtureByLeagueData
                if (!isFirstime){
                    // this.getFixtureByLeagueData();
                    this.getMatchListData(this.currentSubLeague);
                }
            }

            if (this.currentSubLeague.date === '') {
                this.currentSubLeague.colored = false;
            } else {
                this.currentSubLeague.colored = true;
            }
            
        },

        documentClick(e){ //when click outsite document, the subleague dropdown will close
            this.currentSubLeague.isShowDropdown = false;
            e.stopPropagation();
        },

        // Commtted out: 01/11/2022 by YIM: live stream may not ready after countdown
        // countDownEnd(oIndex, level2Index) {
        //     this.matchList[oIndex].matchList[level2Index].showCountDown = false;
        // },
     }
}
</script>

<style scoped>
    video::-webkit-media-controls-timeline,
    video::-webkit-media-controls-current-time-display,
    video::-webkit-media-controls-time-remaining-display {
    display: none;
    }
    .match-list__container {
        position: relative;
    }
    .match-list__group-selector-wrapper {
        position: absolute;
        right: 0;
        top: 0.313rem;/*0.313rem   10rem*/
    }
    .skeleton-match__wrapper{
        width: 100%;
        height: 100%;
        padding:1rem;
        border-radius: 0.5rem;
        box-sizing: border-box;
    }

    .skeleton-match__title{
        width: 50%;
        height: 1rem;
        margin-bottom: .25rem;
    }
    .skeleton-match__content{
        width: 100%;
        height: 2rem;
        margin-bottom: 0.5rem;
    }
    .home-match__win-lose{
        background-color: green;
        border-radius: 50%;
        padding: .3rem;
        color: white;
        width: .75rem;
        border: .1rem solid white;
        height: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
    }
    .home-match__win-lose.W{
        background-color: #5CD66C;    
    }
    .home-match__win-lose.L{
        background-color: #CF283E;    
    }
    .home-match__win-lose.D{
        background-color:   #ED9C5A;    
    }
    .home-match__status{
        padding: 0 .5rem 0 0;/*0 .5rem 0 .8rem*/
        width: 1.6rem;/*1.6rem  5rem*/
        /* box-sizing: border-box; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #CFD9DE;
    }
    .home-match__1-wrapper{
       flex: 1;
    }
    .home-match__1-container{
        justify-content: space-between;
        margin-bottom: .2rem;
        color: #CFD9DE;
    }
    .home-match__1-container:last-child{
        margin-bottom: 0;
    }
    .match-wrapper{
        /* margin-bottom: 1.2rem; comment for world cup */
    }
    .match-container{
        /* display: flex; */
        margin-bottom: .5rem;
    }
    .match-container:hover .home-match__score-card__wrapper{
        background-color: var(--bg-hover);
    }
    .match-container:hover .home-match__static-card__wrapper{
        background-color: var(--bg-hover);
    }
    .match-container-group-wrapper1 {
        display: flex;
    }
    .live-stream-activated .home-match__score-card__wrapper {
        border-bottom-left-radius: 0;
    }
    .live-stream-activated .home-match__static-card__wrapper {
        border-bottom-right-radius: 0;
    }
    .home-match__static-card__wrapper{
        background-color: #35454D;
        padding: 0.5rem 0.25rem .2rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 6.25rem;
        align-items: center;
    }
    .home-match__static-card__brief {
        padding: .5rem 1.5rem;
    }
    .home-match__score-card__wrapper{
        display: flex;
        background-color: var(--color-theme);
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        /* width: 35rem; */
        padding: .5rem 0;
        border-left: .4rem solid transparent;
        flex-grow: 1;
    }
    .home-match__score-card__wrapper.no-odds-match{
        border-radius: .5rem;
    }
    .home-match__score-card__wrapper.no-odds-match-top-rounded {
        border-top-right-radius: 0.5rem;
    }
    .live-selected{
        border-left: .4rem solid var(--bg-selected);
        padding-left: 0.5rem;
    }
	.match-status__live{
		background-color: #CF283E;
		padding: .2rem;
    	border-radius: .3rem;
		font-size:.65rem
	}
    .match-detail{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .match-detail__status{
          width: 1.6rem;
    }
    .match-detail__status-w-auto{
        width :auto;
    }
    .match-detail__score{
        width: 1rem;
        text-align: center;
    }
    .match-detail__score-wrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .match-detail__score-wrapper .score {
        min-width: 1.25rem;
        min-height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.688rem;
        font-weight: 700;
    }
    .penalty-score {
        border-left: 1px solid var(--color-grey-40);
    }
    .match-detail__name{
        width: 9rem;     
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }
    .match-middle{
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
    }
    .match-middle__seperator{
        margin: 0 .5rem;
        font-weight: bold;
        font-weight: bold;
    }
    .nav-pill {
        margin: 0;
    }
     .league-dropdown__svg{
        fill: white;
    }
    .selected  .league-dropdown__svg{
        fill: black;
    }
    .live-stream-on-listing-compact {
        background: #212B30;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding: 0.625rem 0.5rem;
        position: relative;
    }
    .live-stream-icon {
        width: 1.5rem;
        height: 1.5rem;
        top: 0.875rem;
        right: 0.875rem;
        position: absolute;
        background: url('../../static/images/icons/icon_live_video.gif') center/cover no-repeat;
        z-index: 2;
    }
    .live-stream-on-listing-compact video {
        width: 100%;
        height: auto;
        z-index: 1;
    }
    .live-stream-channels-container {
        display: flex;
        padding-top: 0.625rem;
        position: absolute;
        left: 3%;
        bottom: 15%;
        z-index: 2;
        transition: opacity 0.2s ease-out;
    }
    .live-stream-channel-pill {
        color: #ffffff;
        background: var(--bg-theme);
        padding: 0.3rem 0.75rem;
        margin-right: 0.4rem;
        border-radius: 1rem;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: bold;
        opacity: 0.5;
    }
    .live-stream-channel-pill.selected {
        background: var(--bg-selected);
    }
    @media (max-width: 768px) {
        /* .match-list__container {
            position: unset;
        }
        .match-list__group-selector-wrapper {
            position: absolute;
            right: 0;
            top: 10rem;
        } */
    }
    @media (max-width: 768px) {
        .live-stream-on-listing-compact video {
            height: 74vw;
        }
        .live-stream-channels-container {
            left: 3%;
            bottom: 18%;
            z-index: 1;
        }
    }
    @media (max-width: 320px) {
        .live-stream-on-listing-compact video {
            height: 80vw;
        }
        .live-stream-channels-container {
            bottom: 24%;
        }
    }
</style>